export function ensureRelativeURL(url: string) {
    // Check if URL starts with 'http://' or 'https://'
    if (url.startsWith('http://') || url.startsWith('https://')) {
      // Extract the path after the domain
        const urlObject = new URL(url);
        return urlObject.pathname + urlObject.search + urlObject.hash;
    }
    // If URL is already relative, return as is
    return url;
}

export function breadcrumbStepNavigate(url: string): string {
  let redirectUrl:string = '';
  if (typeof(url) !== 'undefined' && url != null) {
      switch(url.toLowerCase()){
          case '/pages/a-solution-for-your-organisation':
          case '/pages/coaching-listing':
          case '/pages/info-central':
              redirectUrl = url.replace('/pages','');
          break;
          default:
              redirectUrl = url;
          break;
      }
  }

  return redirectUrl;
}